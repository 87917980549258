export const rowKey = "sku_id";

// export const columns = [
//   // 门店列表
//   {
//     title: "商品图片",
//     dataIndex: "cover_url",
//     align: "center",
//     width: "10%",
//     customRender: (text, row) => {
//       return {
//         children: <img style="height:50px;" src={text}></img>,
//         attrs: {
//           rowSpan: row.goods_nameRowSpan,//需要合并的数据
//         }
//       }
//     }
//   },
//   // {
//   //   title: "商品编码",
//   //   dataIndex: "goods_id",
//   //   align: "center",
//   //   customRender: (text, row) => {
//   //     return {
//   //       children: text,
//   //       attrs: {
//   //         rowSpan: row.goods_nameRowSpan,//需要合并的数据
//   //       }
//   //     }
//   //   }
//   // },
//   {
//     title: "商品名称",
//     dataIndex: "goods_name",
//     align: "center",
//     customRender: (text, row) => {
//       return {
//         children: text,
//         attrs: {
//           rowSpan: row.goods_nameRowSpan,//需要合并的数据
//         }
//       }
//     }
//   },
//   {
//     title: "商品分类",
//     dataIndex: "goods_type",
//     align: "center",
//     width: "10%",
//     customRender: (text, row) => {
//       return {
//         children: formatType(text),
//         attrs: {
//           rowSpan: row.goods_nameRowSpan,//需要合并的数据
//         }
//       }
//     }
//   },
//   {
//     title: "口味",
//     dataIndex: "flavor_name",
//     align: "center",
//     customRender: (text, row) => {
//       return {
//         children: text,
//         attrs: {
//           rowSpan: row.flavor_nameRowSpan,//需要合并的数据
//         }
//       }
//     }
//   },
//   {
//     title: "尺寸",
//     dataIndex: "specification_name",
//     align: "center",
//   },
//   {
//     title: "最大库存",
//     dataIndex: "max_stock",
//     align: "center",
//   }
// ]

export const goodsType = {
  1: "软点",
  2: "西点",
  3: "蛋糕",
};

export function formatType (val) {
  return goodsType[val];
}

export default {
  rowKey,
  goodsType
};

