<template>
  <div>
    <div class="flex justify-between mt-2 mb-2">
      <div class="flex items-center">
        <span class="text-lg font-bold">本网点的商品货架</span>
        <span class="ml-4" style="color:#aaa;"
          v-if="!shop_manage_goods">产品上下架由城市统一操作</span>
      </div>
      <!-- <h4 class="text-lg font-bold">本网点的商品货架</h4> -->
      <a-button class="ml-3 p-0" type="link" @click="handlerSyncStore">复制其他网点的货架</a-button>
    </div>
    <base-table ref="saleGoods"
                id="sku_id"
                rowKey="sku_id"
                :columnsData="columns"
                :tableData="tableData"
                :rowClassName="setRowClass"
                :row-selection="rowSelection"
                :noPage="true"
                :addHeight="10"
                :total="total"
                :getListFunc="initData"
                :page.sync="page"
                :pageSize.sync="page_count">
      <template #toolbar>
        <div class="flex justify-between">
          <div class="w-full mb-2 flex justify-between">
            <div slot="tabBarExtraContent" class="flex">
              <a-button v-if="!isInEditStock" 
                  @click="handlerEditStock" key="edit">编辑库存</a-button>
              <a-button v-else 
                  @click="handlerCancelEdit" key="cancel-edit">退出编辑</a-button>
            </div>
            <div slot="tabBarExtraContent" class1="flex">
              <a-button class="ml-3" type="primary" 
                v-if="shop_manage_goods" @click="handlerAddGoods">添加商品</a-button>
            </div>
          </div>
        </div>
        <div v-if="isInEditStock" class="flex justify-between mt-1 mb-2">
          <a-button type="link" class="mr-2" size="small" style="padding:0;"
              :disabled="!(rowSelection && rowSelection.selectedRowKeys.length)"
              @click="handleMultiStock">批量修改库存</a-button>
          <a-button type="link" size="small" class="p-0 mr-3"
              @click="handlerSaveStocks">保存库存数据</a-button>
        </div>
      </template>

      <template #max_stock="{ text, record }">
        <a-input-number type="number" :min="0" style="width:100%;"
            v-if="isInEditStock" v-model="record.max_stock" />
        <span v-else>{{ text > 999 ? "999+" : text }}</span>
      </template>
    </base-table>

    <add-goods-modal v-if="isShowAddGoods"
                     :show.sync="isShowAddGoods"
                     :typeId="activeTypeId"
                     :newKeys="newKeys"
                     @ok="handlerAddGoodsOK" />

    <async-outlets-modal v-if="isAsyncOutlets"
                         :show.sync="isAsyncOutlets" />

    <a-modal :visible.sync="isShowMultiStock"
             title="批量设置商品SKU的每日最大库存"
             destroyOnClose
             width="30%"
             @ok="handleMultiStockOk"
             @cancel="isShowMultiStock=false">
      <div class="flex items-center">
        <span>每日最大库存</span>
        <span class="ml-3"
              style="min-width:200px;">
          <a-input v-model="batchStock" placeholder="填写数字" />
        </span>
      </div>
    </a-modal>
  </div>
</template>

<script>
import AddGoodsModal from "./components/add-goods-modal.vue"
import AsyncOutletsModal from "./components/async-outlets-modal.vue"

import { goodsType } from "@/utils/type"
import { rowKey, formatType } from "./columns"
import { rowSpan } from "@/utils/index"
import { cutQuery } from '@/utils/ossImg'
import config from '@/utils/config'

import {
  getSaleGoodsList,
  editGoodsData,
  getSkuListByGoodsId,
} from "@/api/shop.js"

export default {
  components: {
    AddGoodsModal,
    AsyncOutletsModal,
  },
  data () {
    return {
      shop_manage_goods: config.SWITCH_SHOP_MANAGE_GOODS,

      formatType,
      rowKey,
      batchStock: '',
      isShowMultiStock: false,
      columns: [],
      baseColumns: [
        // 门店列表
        {
          title: "图片",
          dataIndex: "cover_url",
          align: "center",
          width: 80,
          customRender: (text, row) => {
            if(text) text = text+cutQuery(50,50)
            return {
              children: <img style="height:50px;" src={text}></img>,
              attrs: {
                rowSpan: row.goods_nameRowSpan, //需要合并的数据
              },
            }
          },
        },
        {
          title: "商品名称",
          dataIndex: "goods_name",
          align: "center",
          customRender: (text, row) => {
            return {
              children: text,
              attrs: {
                rowSpan: row.goods_nameRowSpan, //需要合并的数据
              },
            }
          },
        },
        {
          title: "口味",
          dataIndex: "flavor_name",
          align: "center",

          customRender: (text, row) => {
            return {
              children: text,
              attrs: {
                rowSpan: row.flavor_nameRowSpan, //需要合并的数据
              },
            }
          },
        },
        {
          title: "尺寸",
          dataIndex: "specification_name",
          align: "center",
        },
        // {
        //   title: "售价",
        //   dataIndex: "price",
        //   align: "center",
        //   width: 80,
        //   slots: {
        //     customRender: "price",
        //   },
        //   slotsType: "format",
        //   slotsFunc: (val) => ('￥'+val),
        // },
        {
          title: "最大库存",
          dataIndex: "max_stock",
          align: "center",
          width: 130,
          slots: { customRender: "max_stock" },
        },
        {
          title: "操作",
          dataIndex: "operation",
          align: "center",
          width: 80,
          customRender: (text, row) => {
            return {
              children: (
                <a-button type="link"
                  onClick={() => {
                    this.handlerRemove(row)
                  }}
                >下架</a-button>
              ),
              attrs: {
                rowSpan: row.goods_nameRowSpan, //需要合并的数据
              },
            }
          },
        },
      ],
      isInEditStock: false,
      isAsyncOutlets: false, // 是否展示同步网点数据
      isShowAddGoods: false,
      activeTypeId: 1,
      delivery_limit: 0,
      goodsList: [],
      total: 0,
      typeList: [
        { type_id: "", type_name: "全部" }
      ],
      typeSubList: [],
      tableData: [],
      selectKeys: [],
      newKeys: [],
      page: 1,
      page_count: 20,
      paramsData: {
        off_shelves_goods_data: [],
        update_sku_data: [],
      },
      rowSelection: null,
      baseRowSelection: {
        selectedRowKeys: [],
        onSelect: (record, selected) => {
          if (selected) {
            this.rowSelection.selectedRowKeys.unshift(record.sku_id);
          } else {
            this.rowSelection.selectedRowKeys = this.rowSelection.selectedRowKeys.filter(
              (el) => el != record.sku_id
            );
          }
        },
        onSelectAll: (selected, selectedRows) => {
          if (selected) {
            this.rowSelection.selectedRowKeys = selectedRows.map(
              (el) => el.sku_id
            );
          } else {
            this.rowSelection.selectedRowKeys = [];
          }
        },
      },
    }
  },
  async mounted () {
    for(let id in goodsType){
      this.typeList.push({
        type_id: id,
        type_name: goodsType[id],
      })
    }

    if(this.shop_manage_goods){
      this.columns = this.baseColumns
    }else{
      this.columns = this.baseColumns.filter(el=>{
        return el.dataIndex != "operation"
      })
    }

    this.handlerTypeChange(this.activeTypeId)
  },
  methods: {

    handlerTypeChange(type_id){
      this.activeTypeId = type_id
      if(type_id == 3){ // 蛋糕
        this.handlerCancelEdit()
      }
      this.initData()
    },

    async initData (goods_type) {
      goods_type = goods_type || this.activeTypeId

      // 清空勾选内容
      if(this.rowSelection && this.rowSelection.selectedRowKeys){
        this.rowSelection.selectedRowKeys = []
      }
      // 清空编辑缓存
      this.paramsData.off_shelves_goods_data = []
      this.paramsData.update_sku_data = []

      const { data } = await getSaleGoodsList({
        goods_type: goods_type||"", // test 无用
        page: 1,
        page_count: 300,
      })
      let goods_list  = data.list

      // this.total = data.total_count
      this.selectKeys = goods_list.map((el) => {
        return el.goods_id
      })

      // 组装表格所需数据
      let skuArr = []
      goods_list.map((goods) => {
        goods.is_cake = goods.goods_type==3
        goods.sku_data.map((item) => {
          let obj = goods
              obj.flavor_name = item.flavor_name
              obj.status = 1
              obj.is_cake = goods.is_cake
          if (item.specification_list.length > 0) {
            item.specification_list.map((el) => {
              obj = { ...obj, ...el }
              skuArr.push(obj)
            })
          }
        })
      })
      skuArr = rowSpan(skuArr, "goods_name")

      this.tableData = skuArr
    },

    // 添加商品
    handlerAddGoods () {
      this.isShowAddGoods = true
    },

    // 确认添加商品 参数1：选中又被取消掉的商品，2 选中的商品
    // async handlerAddGoodsOK (unRowKeys, rows) {
    //   let cake_list  = rows.filter((el) => el.goods_type==3).map(gd=>{ return {goods_id: gd.goods_id} })
      
    //   let that = this
    //   this.$confirm({
    //     title: "确定上架？",
    //     async onOk () {
    //       if(cake_list.length){
    //         let cake_params = {
    //           add_goods_data: JSON.stringify(cake_list)
    //         }
    //         await editGoodsData(cake_params, true)
    //       }
    //       that.isShowAddGoods = false
    //       that.$message.success("已上架")
    //       that.initData()
    //     },
    //     onCancel () {
    //       console.log("Cancel")
    //     },
    //   })
    // },

    // debug 待优化 unRowKeys没用
    // 确认添加商品 参数1：选中又被取消掉的商品，2 选中的商品
    async handlerAddGoodsOK (unRowKeys, rows) {
      let sku_ids = rows.map(gd=>gd.goods_id)
      let sku_list = []
      if(sku_ids.length){
        let { data } = await getSkuListByGoodsId({ 
          goods_ids: sku_ids.join(",") 
        }, false)
        data.list.map(goods=>{
          goods.sku_data.map(flavor=>{
            flavor.specification_list.map(sku=>{
              sku_list.push({
                // goods_id: goods.goods_id,
                sku_id: sku.sku_id,
                // max_stock: sku.max_stock || 0,
                // current_stock: sku.current_stock || 0,
                max_stock: 999999999,
                current_stock: 999999999,
              })
            })
          })
        })
      }

      let that = this
      this.$confirm({
        title: "确定上架？",
        async onOk () {
          if(sku_list.length){
            let other_params = {
              update_sku_data: JSON.stringify(sku_list)
            }
            await editGoodsData(other_params)
          }
          that.isShowAddGoods = false
          // that.handlerEditStock()
          that.initData()
          that.$message.success("已上架")
        },
        onCancel () {
          console.log("Cancel")
        },
      })
    },

    // 批量修改最大库存
    handleMultiStock () {
      if (!this.rowSelection.selectedRowKeys) {
        this.$message.warning("请先勾选商品");
        return;
      }

      this.isShowMultiStock = true
    },
    handleMultiStockOk () {
      let skuIds = this.rowSelection.selectedRowKeys

      const params = {
        update_sku_data: JSON.stringify(
          skuIds.map(skuid => {
            return {
              sku_id: skuid,
              max_stock: this.batchStock,
            }
          })
        )
      }

      editGoodsData(params).then((res) => {
        if (res.code === 0) {
          this.isShowMultiStock = false
          this.$message.success("操作完成")
          this.initData()
        }
      })
    },

    handlerSaveStocks(){
      // 如果输入了负数 那么把库存的值加到数组中 在点击提交的时候做判断
      let errStockIndex = this.tableData.findIndex((item) => {
        return item.max_stock < 0
      })
      if(errStockIndex > -1){
        this.$message.error("库存不能为负数")
        return
      }

      // 如果是蛋糕判断有无新增数据，传入新增数据
      let sku_list = this.tableData.map((item) => {
        return {
          sku_id: item.sku_id,
          max_stock: item.max_stock,
          // current_stock: item.current_stock,
        }
      })
      let params = {
        update_sku_data: JSON.stringify(sku_list)
      }
      editGoodsData(params).then((res) => {
        if (res.code === 0) {
          this.handlerCancelEdit()
          this.$message.success("已保存")
          this.initData()
        }
      })
    },

    // 同步网点
    handlerSyncStore () {
      this.isAsyncOutlets = true
    },

    // 下架商品
    handlerRemove (row) {
      let that = this
      this.$confirm({
        title: "下架后，顾客将无法在线上小程序/官网中购买，确定下架?",
        async onOk () {
          let params = {
            off_shelves_goods_data: JSON.stringify([{
              goods_id: row.goods_id,
            }])
          }
          editGoodsData(params, row.is_cake).then((res) => {
            if (res.code === 0) {
              that.$message.success("已下架")
              that.tableData = that.tableData.filter(
                (el) => el.goods_id != row.goods_id
              )
              if(that.rowSelection){
                that.rowSelection.selectedRowKeys = []
              }
            }
          })
        },
        onCancel () {
          console.log("Cancel")
        },
      })
    },

    handlerEditStock () {
      this.isInEditStock = true
      this.rowSelection = this.baseRowSelection
    },
    handlerCancelEdit () {
      this.isInEditStock = false
      this.rowSelection = null
    },

    setRowClass (record) {
      if (record.status == 0) {
        return "goods-disable-status"
      } else {
        return "goods-normal-status"
      }
    },
  },
}
</script>
<style lang="less">
.goods-disable-status {
  background: rgb(205, 205, 205);
  opacity: 0.8;
  &:hover {
    background: rgb(205, 205, 205);
    opacity: 0.8;
  }
}
</style>