<template>
  <a-modal
    :visible="show"
    v-bind="$attrs"
    title="从其他网点复制商品"
    :afterClose="remove"
    width="50%"
    ok-text="复制"
    @cancel="remove"
    @ok="handleOk"
  >
    <div>
      <!-- <a-select v-model="searchParams.outlets_id"
                style="width: 200px"
                @change="handlerChange">
        <a-select-option :value="item.outlets_id"
                         v-for="item in outletsList"
                         :key="item.outlets_id">{{item.name}}
        </a-select-option>
      </a-select>-->
      <div class="ml-3 mb-2">网点名称：<a-select
        show-search
        style="width: 250px"
        placeholder="请输入网点名称"
        :default-active-first-option="false"
        :show-arrow="false"
        :filter-option="false"
        :not-found-content="null"
        @search="handlerShopChange"
        @change="handlerChange"
        v-model="searchParams.outlets_id"
      >
        <a-select-option
          v-for="(item, index) in outletsList"
          :key="index"
          :value="item.outlets_id"
        >{{ item.shop_name }}</a-select-option>
      </a-select></div>
      
    </div>

    <base-table
      style="margin-bottom:-20px;"
      ref="saleGoods"
      id="saleGoods"
      :columnsData="columns"
      rowKey="sku_id"
      :tableData="tableData"
      :total="searchParams.total"
      :getListFunc="initGoodsData"
      :page.sync="searchParams.page"
      :pageSize.sync="searchParams.page_count"
    ></base-table>
  </a-modal>
</template>

<script>
import { getSaleGoodsList, syncOutletsData, getSearchShopList } from "@/api/shop.js"

// import { formatType } from '../columns'
import { rowSpan } from '@/utils/index'
import { cutQuery } from '@/utils/ossImg'

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    isCake: {
      type: Boolean
    },
  },
  data() {
    return {
      columns: [
        // {
        //   title: "分类",
        //   dataIndex: "goods_type",
        //   align: "center",
        //   width: 80,
        //   customRender: (text, row) => {
        //     return {
        //       children: formatType(text),
        //       attrs: {
        //         rowSpan: row.goods_nameRowSpan,//需要合并的数据
        //       }
        //     }
        //   }
        // },
        {
          title: "图片",
          dataIndex: "cover_url",
          align: "center",
          width: 80,
          customRender: (text, row) => {
            if(text) text = text+cutQuery(50,50)
            return {
              children: <img style="height:50px;" src={text}></img>,
              attrs: {
                rowSpan: row.goods_nameRowSpan,//需要合并的数据
              }
            }
          }
        },
        // {
        //   title: "商品编码",
        //   dataIndex: "goods_id",
        //   align: "center",
        //   customRender: (text, row) => {
        //     return {
        //       children: text,
        //       attrs: {
        //         rowSpan: row.goods_nameRowSpan,//需要合并的数据
        //       }
        //     }
        //   }
        // },
        {
          title: "商品名称",
          dataIndex: "goods_name",
          align: "center",
          customRender: (text, row) => {
            return {
              children: text,
              attrs: {
                rowSpan: row.goods_nameRowSpan,//需要合并的数据
              }
            }
          }
        },
        // {
        //   title: "口味",
        //   dataIndex: "flavor_name",
        //   align: "center",
        //   customRender: (text, row) => {
        //     return {
        //       children: text,
        //       attrs: {
        //         rowSpan: row.flavor_nameRowSpan,//需要合并的数据
        //       }
        //     }
        //   }
        // },
        // {
        //   title: "尺寸",
        //   dataIndex: "specification_name",
        //   align: "center",
        // },
        // {
        //   title: "最大库存",
        //   dataIndex: "max_stock",
        //   align: "center",
        // }
      ],
      outletsList: [],
      tableData: [],
      searchParams: {
        page: 1,
        page_count: 40,
        outlets_id: "",
        // isCake: this.isCake
      }
    }
  },
  async mounted() {
    // if (this.isCake) {
    //   this.columns.pop()
    // }
  },
  methods: {

    // 搜索门店
    async handlerShopChange(value) {
      const params = {
        shop_name: value,
      }
      if (value) {
        const { data, code } = await getSearchShopList(params)
        if (code === 0) this.outletsList = data.list
      }
    },

    async initGoodsData() {
      const { data, code } = await getSaleGoodsList(this.searchParams)
      if (code == 0) {
        this.searchParams.total = data.total_count
        // 组装表格所需数据
        let skuArr = []
        data.list.map(goods => {
          goods.sku_data.map(item => {
            let obj = goods
            obj.flavor_name = item.flavor_name
            obj.status = 1
            if (item.specification_list.length > 0) {
              item.specification_list.map(el => {
                obj = { ...obj, ...el }
                skuArr.push(obj)
              })
            }
          })
        })
        skuArr = rowSpan(skuArr, 'goods_name')

        this.tableData = skuArr
      }
    },


    handlerChange() {
      this.initGoodsData()
    },

    remove() {
      this.$emit('update:show', false)
    },
    handleOk() {
      const _this = this
      this.$confirm({
        title: '提示',
        content: '同步后，会完全覆盖本店售卖商品',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        async onOk() {
          await syncOutletsData({ outlets_id: _this.searchParams.outlets_id, isCake: true })
          // await syncOutletsData({ outlets_id: _this.searchParams.outlets_id, isCake: false })
          _this.$message.success('操作成功')
          _this.$emit('update:show', false)
          _this.$parent.initData()
        },
        onCancel() {
          console.log('Cancel');
        },
      });

    },
  }
}
</script>
